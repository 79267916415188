import React, { useContext, useState } from "react";
import { Col } from "./Layout";
import styles from "./MainCard.module.scss";
import { AppContext } from "../../GlobalContext";
import Button from "./Button";
import CreditValueEditorModal from "../modals/CreditValueEditorModal";
import NetworkService from "../../NetworkService";
import { refetchCompanyData, toasterFunction } from "../utils";
import { Message } from "rsuite";

function MainCard() {
  const { user: a, companyData: b } = useContext(AppContext);
  const [companyData, setCompanyData] = b;
  const [user] = a;

  console.log(companyData?.company?.active);

  const [openCreditEditModal, setOpenCreditEditModal] = useState(null);

  const requestCompanyCredit = async (credit) => {
    await NetworkService.requestCompanyCredit(credit);
    setOpenCreditEditModal(null);
    refetchCompanyData(setCompanyData);
    toasterFunction(
      <Message closable type="success">
        Mail with invoice successfully sent.
      </Message>
    );
  };

  return (
    <Col className={styles.noPadding} xl={4} sm={12} lg={6} xs={12} md={6}>
      <CreditValueEditorModal
        btnLabel="Add credit"
        header="How much credit you want to add?"
        subHeader="Credit in RSD / Minimum 5000.00 RSD"
        initialLimitValue={5000}
        onSubmit={(credit) => requestCompanyCredit(credit)}
        open={!!openCreditEditModal}
        close={() => setOpenCreditEditModal(false)}
        minValue={5000}
      />
      <div className={styles.mainCard}>
        <InfoItem flex={1}>
          <div>
            <span className={styles.label}>Profile owner:</span>
            <span className={styles.companyName}>{companyData?.company?.name}</span>
          </div>
          <div className={styles.mainInfoText}>{user?.user?.name}</div>
        </InfoItem>
        <InfoItem flex={2}>
          <div className={styles.label}>ID:</div>
          <div className={styles.mainInfoText}>{user?.user?.id}</div>
        </InfoItem>

        <Button
          disabled={companyData?.company?.active == 0}
          onClick={() => setOpenCreditEditModal(true)}
          className={styles.addCreditButton}
          variant="green">
          Add Credit
        </Button>
      </div>
    </Col>
  );
}

export default MainCard;

const InfoItem = ({ children, flex }) => {
  return (
    <div style={{ flex: flex }} className={styles.infoItem}>
      {children}
    </div>
  );
};
