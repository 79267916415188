import React from "react";
import styles from "./DownloadAppSection.module.scss";
import { Col, Row } from "../common/Layout";
import Button from "../common/Button";
import cargoLogo from "../../static/images/cargo-logo-header-dark.svg";

import backgroundRight from "../../static/images/bcg_right_login.svg";

import google from "../../static/stores/btn_google.svg";
import apple from "../../static/stores/btn_apple.svg";
import huawei from "../../static/stores/btn_huawei.svg";
import { useNavigate } from "react-router";
function DownloadAppSection() {
  const navigate = useNavigate();

  return (
    <>
      <Col style={{ background: `url(${backgroundRight})` }} xl={6} lg={6} xs={12} className={styles.loginRight}>
        <div>Don’t have Company account on our platform?</div>

        <div>
          If you have company and you need our services, you can register on link below and we can start amazing jurney
          together.
        </div>

        <Button className={styles.registerButton} onClick={() => navigate("/register")} variant="white">
          Register now
        </Button>

        <img alt="cargo logo dark" src={cargoLogo} />
        <div className={styles.description}>
          With the CarGo app enjoying the city has never been that easy. Download the app and enter where you want to
          go. A nearby service provider will help you to get wherever you want.
        </div>
        <Row className={styles.storesWrap}>
          <a href="https://play.google.com/store/apps/details?id=com.appcargo.user" target="_blank" rel="noreferrer">
            <img alt="store img" className={styles.appStore} src={google} />
          </a>
          <a href="https://apps.apple.com/us/app/car-go-anywhere/id999970383" target="_blank" rel="noreferrer">
            <img alt="store img" className={styles.appStore} src={apple} />
          </a>
          <a
            href="https://appgallery.huawei.com/#/app/C103244763?channelId=EURSMKT20210219CG&detailType=0"
            target="_blank"
            rel="noreferrer">
            <img alt="store img" className={styles.appStore} src={huawei} />
          </a>
        </Row>
      </Col>
    </>
  );
}

export default DownloadAppSection;
