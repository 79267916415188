import React, { useState } from "react";
import ReactModalWrap from "../common/ReactModalWrap";
import TextField from "../common/TextField";
import { DatePicker, DateRangePicker, Message } from "rsuite";
import styles from "./Modal.module.scss";
import Button from "../common/Button";
import NetworkService from "../../NetworkService";
import { toasterFunction } from "../utils";

const initialData = {
  date: new Date(),
  email: "",
};

function InvoicesModal({ isOpen, close, userId }) {
  const { afterToday, before, combine } = DateRangePicker;
  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const getReport = (formData) => {

    setLoading(true)
    if(userId) {
        NetworkService.getAllInvoicesForUser(userId, formData)
        .then((res) => {
          setFormData(initialData);
          setLoading(false);
          close();
          toasterFunction(
            <Message closable type="success">
                Invoices successfully sent to your email.
            </Message>
          );
        })
        .catch((err) => console.log(err));
    } else {
        NetworkService.getAllInvoices(formData)
        .then((res) => {
          setFormData(initialData);
          setLoading(false);
          close();
          toasterFunction(
            <Message closable type="success">
                Invoices successfully sent to your email.
            </Message>
          );
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <ReactModalWrap isOpen={isOpen} close={close}>
      <div className={styles.content}>
        <div className={styles.header}>Download fiscal invoices for selected period</div>
        <div className={styles.label}>Enter month</div>
        <DatePicker
          oneTap
          menuClassName={styles.rsuiteMenu}
          size="lg"
          limitStartYear={5}
          limitEndYear={2}
          shouldDisableDate={combine(afterToday(), before(new Date("01-01-2020")))}
          className={styles.datePicker}
          preventOverflow
          value={formData.date}
          format="yyyy-MM"
          onChange={(val) => {
            setFormData({ ...formData, date: val });
          }}
        />
        <TextField
          labelClassName={styles.label}
          label="Receiving email"
          placeHolder="Enter receiving email"
          onChange={(val) => setFormData({ ...formData, email: val })}
          type="email"
        />

        <Button
          disabled={!formData?.date || loading}
          variant="green"
          className={styles.sendButton}
          onClick={() => {
            getReport(formData);
          }}>
          Send report
        </Button>
      </div>
    </ReactModalWrap>
  );
}

export default InvoicesModal;
