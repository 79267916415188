import React, { useContext, useState } from "react";
import styles from "./RegisterPage.module.scss";
import { Col, Row } from "../common/Layout";
import TextField from "../common/TextField";
import Button from "../common/Button";
import { AppContext } from "../../GlobalContext";

import backgroundLeft from "../../static/images/bcg_left_login.svg";
import backgroundImage from "../../static/images/modern-businessman-sitting-car.png";

import NetworkService from "../../NetworkService";
import { Link } from "react-router-dom";
import google from "../../static/stores/btn_google.svg";
import apple from "../../static/stores/btn_apple.svg";
import huawei from "../../static/stores/btn_huawei.svg";
import { SelectPicker } from "rsuite";
import classNames from "classnames";

const initialData = {
  company_type: "prepaid",
};

function RegisterPage() {
  const [formData, setFormData] = useState(initialData);

  const [step, setStep] = useState(1);

  const [error, setError] = useState(false);

  const handleFormDataChange = (propName, value) => {
    let validationDraft = validationList;
    delete validationDraft[propName];
    setValidationList(validationDraft);
    setFormData({
      ...formData,
      [propName]: value,
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    registerFunc();
  };

  const [validationList, setValidationList] = useState({});

  const registerFunc = (e) => {
    setError(false);
    NetworkService.registerCompany({ ...formData, company_type: "prepaid", pib: Number.parseInt(formData.pib) })
      .then((res) => {
        setAdminCreatingModal(res);
        setStep(2);
        localStorage.setItem("companyData", JSON.stringify(res));
      })
      .catch((res) => {
        if (res.status === 422) {
          setValidationList(res.data);
        }
        setError(res);
      });
  };

  console.log(validationList);

  const [adminCreatingModal, setAdminCreatingModal] = useState(false);

  return (
    <Row className={styles.loginPage}>
      {step === 1 && (
        <Col style={{ background: `url(${backgroundLeft})` }} xl={6} lg={6} xs={12} className={styles.loginLeft}>
          <div className={styles.header}>Step 1: Fill your company data</div>
          <div className={styles.description}>
            Fill in the fields below and open a company account. After completing the form, you need to download the
            contracts, sign them, and send them to us. Once the account is opened, one of our team members will contact
            you and provide further clarification.
          </div>

          <Link className={styles.forgotPasswordLink} to="/login">
            Already have account?
          </Link>

          <form onSubmit={(e) => formSubmit(e)} className={styles.loginForm}>
            <TextField
              className={classNames(styles.inputClass, {
                [styles.invalid]: Object.keys(validationList)?.includes("name"),
              })}
              label={"Company name"}
              onChange={(val) => handleFormDataChange("name", val)}
              name="name"
              type="string"
              placeHolder="Company name...."
            />
            <TextField
              className={classNames(styles.inputClass, {
                [styles.invalid]: Object.keys(validationList)?.includes("address"),
              })}
              label={"Company email"}
              onChange={(val) => handleFormDataChange("address", val)}
              name="address"
              type="email"
              placeHolder="email@email.com"
            />

            <label>Company type</label>
            <SelectPicker
              className={styles.selectPicker}
              searchable={false}
              onChange={(val) => handleFormDataChange("company_type", val)}
              value={formData?.company_type}
              data={[
                {
                  label: "Prepaid",
                  value: "prepaid",
                },
                {
                  label: "Postpaid",
                  value: "postpaid",
                },
              ]}
              name="company_type"
            />
            <TextField
              className={classNames(styles.inputClass, {
                [styles.invalid]: Object.keys(validationList)?.includes("pib"),
              })}
              label={"VAT ID"}
              onChange={(val) => handleFormDataChange("pib", val)}
              name="pib"
              type="string"
              placeHolder="123456789"
            />
            <TextField
              className={classNames(styles.inputClass, {
                [styles.invalid]: Object.keys(validationList)?.includes("company_id"),
              })}
              onChange={(val) => handleFormDataChange("company_id", val)}
              name="company_id"
              type="string"
              label="Company id"
              placeHolder="12345678"
            />
            <TextField
              className={classNames(styles.inputClass, {
                [styles.invalid]: Object.keys(validationList)?.includes("finance_mail"),
              })}
              onChange={(val) => handleFormDataChange("finance_mail", val)}
              name="finance_mail"
              type="email"
              label="Finance mail"
              placeHolder="mail@mail.com"
            />
            {validationList &&
              Object.values(validationList).map((val) => <div className={styles.errorOccurred}>{val[0]}</div>)}
            <Step step={1} />
            <Button className={styles.submitButton} variant="white" type="submit">
              Next
            </Button>
          </form>
        </Col>
      )}

      {step === 2 && (
        <Col style={{ background: `url(${backgroundLeft})` }} xl={6} lg={6} xs={12} className={styles.loginLeft}>
          <AdminCreating companyId={adminCreatingModal?.id} />
        </Col>
      )}
      <Col style={{ background: `url(${backgroundImage})` }} xl={6} lg={6} xs={12} className={styles.rightPart}>
        <Row className={styles.storesWrap}>
          <a href="https://play.google.com/store/apps/details?id=com.appcargo.user" target="_blank" rel="noreferrer">
            <img alt="store img" className={styles.appStore} src={google} />
          </a>
          <a href="https://apps.apple.com/us/app/car-go-anywhere/id999970383" target="_blank" rel="noreferrer">
            <img alt="store img" className={styles.appStore} src={apple} />
          </a>
          <a
            href="https://appgallery.huawei.com/#/app/C103244763?channelId=EURSMKT20210219CG&detailType=0"
            target="_blank"
            rel="noreferrer">
            <img alt="store img" className={styles.appStore} src={huawei} />
          </a>
        </Row>
      </Col>
    </Row>
  );
}

export default RegisterPage;

const AdminCreating = ({ companyId }) => {
  const [formData, setFormData] = useState();

  const { user: a } = useContext(AppContext);

  const [user, setUser] = a;

  const handleFormDataChange = (propName, value) => {
    let validationDraft = validationList;
    delete validationDraft[propName];
    setValidationList(validationDraft);
    setFormData({
      ...formData,
      [propName]: value,
    });
  };

  const [validationList, setValidationList] = useState({});

  console.log(validationList);

  return (
    <div className={styles.modalContent}>
      <div className={styles.header}>Step 2: Register admin account</div>

      <div className={styles.description}>
        Fill in the fields below and open a company account. After completing the form, you need to download the
        contracts, sign them, and send them to us. Once the account is opened, one of our team members will contact you
        and provide further clarification.
      </div>
      <TextField
        className={classNames(styles.inputClass, {
          [styles.invalid]: Object.keys(validationList)?.includes("name"),
        })}
        onChange={(val) => handleFormDataChange("name", val)}
        name="name"
        type="string"
        label="Administrator name"
        placeHolder="Name...."
      />
      <TextField
        className={classNames(styles.inputClass, {
          [styles.invalid]: Object.keys(validationList)?.includes("phone"),
        })}
        onChange={(val) => handleFormDataChange("phone", val)}
        name="phone"
        type="string"
        label="Phone number"
        placeHolder="+381......"
      />
      <TextField
        className={classNames(styles.inputClass, {
          [styles.invalid]: Object.keys(validationList)?.includes("email"),
        })}
        onChange={(val) => handleFormDataChange("email", val)}
        name="email"
        type="email"
        label="Email"
        placeHolder="email@email.com"
      />
      <TextField
        className={classNames(styles.inputClass, {
          [styles.invalid]: Object.keys(validationList)?.includes("password"),
        })}
        onChange={(val) => handleFormDataChange("password", val)}
        name="password"
        type="password"
        label="Password"
        placeHolder="******"
      />
      {validationList &&
        Object.values(validationList).map((val) => <div className={styles.errorOccurred}>{val[0]}</div>)}
      <Step step={2} />

      <Button
        onClick={() =>
          NetworkService.registerAdmin({ ...formData, company_id: companyId })
            .then(() => {
              NetworkService.login(formData).then((res) => {
                setUser(res);
              });
            })
            .catch((res) => {
              if (res.status === 422) {
                setValidationList(res.data);
              }
            })
        }
        className={styles.submitButton}
        variant="green">
        Create administrator
      </Button>
    </div>
  );
};

const Step = ({ step }) => {
  return (
    <div className={styles.stepWrap}>
      <div className={styles.step} style={{ backgroundColor: `${step === 1 ? "#3BD4AE" : "#B0B4BA"}` }} />
      <div className={styles.step} style={{ backgroundColor: `${step === 2 ? "#3BD4AE" : "#B0B4BA"}` }} />
    </div>
  );
};
