import React from "react";
import styles from "./Cards.module.scss";
import { Col } from "./common/Layout";
import classNames from "classnames";
import Button from "./common/Button";
import NetworkService from "../NetworkService";
import { downloadFile } from "./utils";
import donwloadIcon from "../static/images/download-icon.svg";
import editIcon from "../static/images/edit.svg";

function Cards({ header, value, button = null, onClick }) {
  return (
    <Col
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      className={classNames(styles.colClass, {
        [styles.hover]: onClick,
      })}
      xl={6}
      sm={6}
      md={12}
      lg={12}>
      <div className={styles.cardWrap}>
        <div className={styles.header}>
          {header} {button}
        </div>
        <div className={styles.value}>{value}</div>
      </div>
    </Col>
  );
}

export function SmallCards({ header, value, customRender, editButtonFunction }) {
  return (
    <Col xl={3} sm={6} lg={3} xs={12} md={6}>
      <div className={styles.cardWrap}>
        <div title={header} className={styles.header}>
          {header}
        </div>
        {value && (
          <div title={value} className={styles.smallCardValue}>
            {value}
          </div>
        )}
        {!!customRender && <div className={styles.smallCardValue}>{customRender}</div>}
        {!!editButtonFunction && (
          <Button onClick={() => editButtonFunction()} variant="white" className={styles.editButton}>
            <img alt="edit" src={editIcon} /> Edit
          </Button>
        )}
      </div>
    </Col>
  );
}

export default Cards;

export function InvoiceCards({ invoice }) {
  const { the_transaction_amount, name } = invoice;

  const downloadInvoice = () => {
    NetworkService.downloadInvoice(invoice).then((res) => {
      if (res?.data) {
        downloadFile(res?.data, `${invoice.start_date}-${invoice.end_date}-${invoice.the_transaction_amount}.pdf`);
      }
    });
  };

  return (
    <Col className={styles.colClass} xl={3} xs={12} sm={12} md={6} lg={3}>
      <div className={styles.cardWrap}>
        <div className={styles.fleetId}>
          <span> Fleet ID:</span> {the_transaction_amount}
        </div>
        <div className={styles.fleetName}> {name}</div>
        <Button
          variant="white"
          className={styles.downloadButton}
          onClick={() => {
            downloadInvoice();
          }}>
          <img alt="download" src={donwloadIcon} />
          Download
        </Button>
      </div>
    </Col>
  );
}
