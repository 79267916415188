import React from "react";
import Modal from "react-modal";
import styles from "./ReactModalWrap.module.scss";
import Button from "./Button";
import { ReactComponent as CloseModalSvg } from "../../static/close-modal.svg";

function ReactModalWrap({ isOpen, close, children, customStyle = { overlay: {}, content: {} }, ...rest }) {
  const customStyles = {
    overlay: {
      zIndex: 99,
      backgroundColor: "rgba(8, 49, 39, 0.6)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backdropFilter: "blur(5px)",
      ...customStyle?.overlay,
    },
    content: {
      opacity: 1,
      // transition: "all 300ms",
      position: "static",
      padding: "0px",
      right: "auto",
      bottom: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderRadius: "15px",
      border: 0,
      maxHeight: "99%",
      background: "#F3F6F6",
      ...customStyle?.content,
    },
  };

  return (
    <div>
      <Modal
        preventScroll={true}
        appElement={document.getElementById("app-root")}
        style={customStyles}
        onRequestClose={() => close()}
        isOpen={isOpen}
        shouldCloseOnEsc={true}
        closeTimeoutMS={10}
        shouldCloseOnOverlayClick={true}
        {...rest}>
        <div className={styles.content}>
          <Button onClick={() => close(false)} className={styles.collapseButton} variant="white">
            <CloseModalSvg />
          </Button>
          {children}
        </div>
      </Modal>
    </div>
  );
}

export default ReactModalWrap;
